/* textEditor 설정 */
.toastui-editor-contents p,
.toastui-editor-contents h1,
.toastui-editor-contents h2,
.toastui-editor-contents h3,
.toastui-editor-contents h4,
.toastui-editor-contents h5,
.toastui-editor-contents h6 {
    margin-bottom: 24px !important;
}

/* carousel 설정 */
.slick-dots {
    display: flex !important;
    bottom: -80px;
    position: static;
    width: 100%;
    overflow: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    margin-top: 20px;
}

.slick-dots li {
    width: 100px;
    height: 100px;
}
